@mixin button() {
    $p: 1.2em;
    --p: #{$p};
    --ph: 0.75em;
    --pr: 2em;
    --aniX: var(--X, 0);
    --aniY: var(--Y, -1);
    --aniExclude: outline, outline-offset;
    @extend %animateBut;
    @extend %fontTitoli;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    padding: var(--p) calc(var(--p) + var(--ph) + var(--pr)) calc(var(--p) - 0.3em)
        calc(var(--p) + var(--ph));
    background: var(--colFondoBottoni, var(--colPrimario));
    color: var(--colTextBottoni, var(--colBianco));
    border: 1px solid var(--colBordoBottoni, transparent);
    font-weight: $fontStrong;
    font-size: 1em;
    text-transform: uppercase;
    line-height: 1.2;
    text-align: center;
    position: var(--buttonPosition, relative);
    z-index: var(--buttonZ, 1);
    overflow: hidden;
    width: 100%;
    border-radius: calc(var(--p) * 2 + 1em);
    transform: var(--btTransform, scale3d(1, 1, 1));
    @media screen and (min-width: 36em) {
        width: auto;
        min-width: min(15em, 100%);
    }
    &::before {
        @extend %fontIcons;
        display: flex;
        align-items: center;
        margin: 0;
        position: absolute;
        left: 100%;
        top: 50%;
        font-size: 1.6em;
        padding: 0 1.75em 0 1em;
        transform: translate3d(-100%, -50%, 0);
    }
    &:not([class*='icon-']) {
        &::before {
            @extend %icon-arrow-right;
        }
    }
    :where(&) + & {
        margin-top: 1em;
        @media screen and (min-width: 36em) {
            &:not(.button--wide) {
                margin-top: 0;
                margin-left: 1em;
                .np_a11y & {
                    margin-left: 2.5em;
                }
            }
        }
    }
    &::after {
        @extend %animate2;
        content: attr(data-hover);
        @include abs-cover(-1);
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--colPrimarioChiaro);
        color: var(--colBianco);
        border-radius: calc(var(--p) * 2);
        opacity: 0;
        transform: scale3d(0.8, 0.8, 1);
        // transform: translate3d(calc(-100% * var(--aniX)), calc(-100% * var(--aniY)), 0);
    }

    a:hover &:not(.button--shy),
    &:hover {
        color: var(--colTextBottoniHover, var(--colBianco));
        padding-left: calc(var(--p) + var(--ph) + var(--pr));
        padding-right: calc(var(--p) + var(--ph));
        &::before {
            animation: bt-arrow 0.8s forwards;
        }
        // &::after {
        //     opacity: 1;
        //     transform: none;
        // }
    }

    @keyframes bt-arrow {
        50% {
            left: 100%;
            transform: translate3d(0, -50%, 0);
        }
        50.001% {
            left: 0;
            transform: translate3d(-100%, -50%, 0);
        }
        100% {
            left: 0;
            transform: translate3d(0, -50%, 0);
        }
    }

    &--small {
        --p: #{$p - 0.5};
    }
    &--xsmall {
        --p: 0.5em;
        --pr: 0em;
        --ph: 0.2em;
        font-size: 0.8em;
        @media screen and (min-width: $screenSmall) {
            --ph: 0.75em;
        }
        &::before {
            display: none;
        }
        &::after {
            @extend %animate;
            content: '';
            @include abs-cover(-1);
            background: var(--colPrimario);
            transform: scale3d(0.8, 0.8, 1);
            opacity: 0;
            border-radius: calc(var(--p) * 2 + 1em);
        }
        &:hover {
            --colTextBottoniHover: var(--colBianco);
            &::after {
                opacity: 0.5;
                transform: none;
            }
        }
    }
    &--big {
        --p: #{$p + 0.5};
    }
    &--wide {
        display: flex;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    &--alternate {
        --colFondoBottoni: var(--colContrasto);
    }
    &--no-icon {
        --pr: 0em;
        --colTextBottoni: var(--colText);
        --colFondoBottoni: var(--colPrimarioChiaro);
        font-family: $fontTesto;
        text-transform: none;
        &::before {
            display: none;
        }
        &::after {
            @extend %animate;
            content: '';
            @include abs-cover(-1);
            background: var(--colPrimario);
            transform: scale3d(0.8, 0.8, 1);
            opacity: 0;
            border-radius: calc(var(--p) * 2 + 1em);
        }
        &:hover {
            --colTextBottoniHover: var(--colBianco);
            &::after {
                opacity: 1;
                transform: none;
            }
        }
    }
    &--outlined {
        --colFondoBottoni: transparent;
        --colBordoBottoni: currentColor;
        --colTextBottoni: false;
    }
    &--white {
        --colTextBottoni: var(--colBianco);
    }
    &--no-button {
        border: none;
        color: var(--colTextBottoni, inherit);
        &::after {
            display: none;
        }
        &:hover {
            --colTextBottoni: unset;
            color: var(--colContrasto);
        }
    }
    &--icon {
        --aniTime: 0.3s;
        width: auto;
        padding: calc(var(--p) - 0.7em);
        min-width: 0;
        &::before {
            position: static;
            margin: 0;
            padding: 0;
            font-size: 1.2em;
            transform: none;
        }
        &:hover {
            padding: calc(var(--p) - 0.7em);
            &::before {
                animation: none;
            }
        }
    }
    &--rounded {
        height: var(--buttonHeight, 4.5em);
        width: var(--buttonHeight, 4.5em);
        padding: var(--p);
        min-width: 0;
        border-radius: 50%;
    }
    &--disabled {
        --colTextBottoni: var(--colGrigioScuro);
        --colFondoBottoni: var(--colGrigio);
        opacity: 0.5;
        cursor: auto;
        pointer-events: none;
    }
    &[href^='http']:not(.button--standard) {
        &::before {
            order: 2;
            margin-right: 0;
            margin-left: 0.5em;
        }
    }
}
