@import '../../../node_modules/nouislider/dist/nouislider';

.noUi {
    $ui: &;
    &-horizontal {
        height: 2px;
        box-shadow: none;
        border: none;
        background: var(--colPrimarioChiaro);
        #{$ui} {
            &-handle {
                width: 16px;
                height: 16px;
                right: -8px;
                top: -7px;
                border-radius: 50%;
            }
        }
    }
    &-connect {
        background: var(--strip-text-color, var(--colText));
    }
    &-handle {
        background: var(--strip-text-color, var(--colText));
        border-color: var(--strip-text-color, var(--colText));
        box-shadow: none;
        &::before,
        &::after {
            display: none;
        }
    }
}
