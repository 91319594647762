@use 'sass:math';

.np-cursor {
    &.slick-cursor-dark {
        --cursorBg: #fff;
        --cursorBorder: var(--colText);
        --cursorText: var(--colText);
    }
    &__text {
        .slick-cursor-left &,
        .cursor-left & {
            &::before {
                @extend %fontIcons;
                @extend %icon-arrow-left;
                margin: 0;
                font-size: var(--iconSize, 2em);
            }
        }
        .slick-cursor-right &,
        .cursor-right & {
            &::before {
                @extend %fontIcons;
                @extend %icon-arrow-right;
                margin: 0;
                font-size: var(--iconSize, 2em);
            }
        }
        .cursor-up & {
            &::before {
                @extend %fontIcons;
                @extend %icon-arrow-up;
                margin: 0;
                font-size: var(--iconSize, 2em);
            }
        }
        .cursor-down & {
            &::before {
                @extend %fontIcons;
                @extend %icon-arrow-down;
                margin: 0;
                font-size: var(--iconSize, 2em);
            }
        }
    }
}

.buttons {
    margin-top: calc(var(--vpad) / 2);
}

// ! PAGE-CONTENT ===========================================================
.page {
    &__content,
    &__footer {
        opacity: 1;
    }
}

.page {
    &__content {
        .page--nobanner & {
            padding-top: calc(var(--header-height) / 16 * 1em / var(--fontSize));
            // display: flex;
            // flex-direction: column;
            flex: 1 1 auto;
        }

        &--fixed-row {
            .page__content {
                position: relative;
                display: grid;
                grid-template-columns: minmax(10vw, auto) minmax(50vw, 1fr);

                .strip {
                    grid-column: 2/2;
                    .container {
                        margin-left: 0;
                    }
                    &--fixed-row {
                        --hpad: calc(var(--vpad) / 2);
                        grid-column: 1/1;
                        grid-row: 1/50;
                        padding-top: calc(var(--header-height) * 1px);
                        width: 33vw;
                        &.strip--narrow {
                            width: 12em;

                            @media screen and (max-width: 43.75em) {
                                // ! 700
                                width: 9em;
                            }
                            ~ .strip .container {
                                margin-left: auto;
                            }
                        }
                        .strip__data {
                            height: 100%;
                        }
                        .container {
                            height: 100%;
                            max-width: math.div($contWidth, 4);
                            margin-right: 0;
                            margin-left: auto;
                        }
                    }
                }
                > .strip:first-child {
                    padding-top: calc(var(--header-height) / 16 * 1em);
                }
            }

            @media screen and (max-width: 43.75em) {
                // ! 700
                &[data-fixed-stop] {
                    .page__content {
                        display: block;
                        .strip {
                            &--fixed-row {
                                width: auto !important;
                                .container {
                                    margin-left: auto;
                                    margin-right: auto;
                                }
                            }
                            .container {
                                margin-left: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

// ! Banner -------
.banner {
    $h: 60vh;
    --colDots: #fff;
    position: relative;
    overflow: hidden;
    &.TR {
        --pinX: 100%;
        --pinY: 0;
    }
    &.BR {
        --pinX: 100%;
        --pinY: 100%;
    }
    &.BL {
        --pinX: 0;
        --pinY: 100%;
    }
    &.TL {
        --pinX: 0;
        --pinY: 0;
    }
    .slick-dots {
        position: absolute;
        z-index: 10;
        bottom: calc(var(--vpad) / 2);
        left: 50%;
        transform: translateX(-50%);
        justify-content: center;

        a {
            position: static;
            margin: 0 0.5em;
        }
    }
    &__slider,
    &__slide {
        height: $h;
        .home & {
            height: calc(#{$h} + var(--header-height) * 1px);
        }
    }
    &__slider {
        z-index: 1;
        padding: 0;
        overflow: hidden;
        &__container {
            overflow: hidden;
        }
    }
    &__slide {
        width: 100%;
        display: block !important;
        @extend %coverImage;
    }
    &__video {
        @include abs-cover(1);
    }
    &__text {
        @extend %container;
        @extend %textWhite;
        position: absolute;
        z-index: 5;
        top: 50%;
        right: 0;
        left: 0;
        transform: translate3D(0, -50%, 0);
        text-align: center;
    }
    &__buttons {
        margin-top: calc(var(--vpad) / 2);
    }
}

// ! Accordion -------
.accordion {
    &__switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    &__panel {
        display: none;
        .no-js & {
            display: block;
        }
        &.open {
            display: block;
        }
    }
}
.toggle {
    &__switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 1em;
        border-bottom: 1px solid;
        cursor: pointer;
        &::after {
            @extend %fontIcons;
            @extend %icon-plus;
            flex: 0 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 calc(var(--vpad) / 4);
            font-size: 1rem;
        }
        &.toggle-open {
            &::after {
                @extend %icon-cancel;
            }
        }
    }
    &__panel {
        display: none;
        .no-js & {
            display: block;
        }
    }
}

.np-logo {
    max-width: 400px !important;
}

[data-image-link] {
    position: relative;
    display: inline-block;
    cursor: none;
    color: inherit;
}
.image {
    &__link {
        $w: 2em;
        @extend [class^='icon-'];
        @extend .icon-plus;
        padding: 0.2em;
        position: absolute;
        z-index: 20;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        pointer-events: none;
        color: var(--colText);

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.5em;
        width: $w;
        height: $w;
        &::before {
            margin: 0;
        }
        &::after {
            content: '';
            @include abs-cover();
            border: 1px solid var(--colContrasto);
            border-radius: 50%;
            //clip-path: polygon(0 0, 88% 0, 88% 100%, 0 100%);
        }
        &--float {
            pointer-events: none;
            position: fixed;
        }
        &.hidden {
            display: none;
        }
    }
    &__credits {
        position: relative;
        z-index: 10;

        font-size: 0.8rem;
        text-align: right;
    }
}

[data-drag-scroll] {
    cursor: grab;
    user-select: none;
    &.is-dragging {
        cursor: grabbing;
    }
}

.map {
    &-content {
        @extend %fontTesto;
        color: var(--colText);
        font-size: 1rem;
        text-align: center;
        min-width: 16em;
        padding: 0.2em;
    }
    &__image {
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto 0.7em;
    }
}
.gm-style {
    .gm-style-iw {
        border-radius: 8px;
        box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
        button {
            width: auto !important;
            height: auto !important;
            top: 3px !important;
            right: 3px !important;
            &::before {
                @extend %fontIcons;
                @extend %icon-menu-close;
                margin: 0;
                font-size: 1rem;
                color: var(--colNero);
            }
            span,
            img {
                display: none !important;
            }
        }
    }
}

// ! PAGE-FOOTER ===========================================================
.page__footer {
    opacity: 1;
    flex: 0 0 auto;
    margin-top: auto;
}

.scroll-top {
    --aniProp: opacity, visibility, background;
    @extend %animate2;
    display: block;
    position: fixed;
    z-index: 20;
    bottom: 1rem;
    right: .5rem;
    opacity: 0;
    visibility: hidden;
    font-size: 0.8em;
    
    @media screen and (min-width: 36em) {
        right: var(--vpad);
        font-size: 1em;
    }

    .opaque & {
        opacity: 1;
        visibility: visible;
        .m-open & {
            opacity: 1;
            visibility: hidden;
        }
    }
    a {
        $w: 2.5em;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: $w;
        height: $w;
        padding: 0.5em;
        font-size: 1em;
        line-height: 1.1;
        opacity: 0.5;
        &::before {
            font-size: 1.3em;
            margin: 0;
            width: auto;
        }
        &:hover {
            opacity: 1;
        }
    }
}
