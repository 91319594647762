@use 'sass:math';

@import '../extend';
@import '../extend_site';

.bld-form {
    $fr: &;
    &__row {
        display: grid;
        gap: calc(var(--vpad) / 3);
        background: var(--bgStrip, transparent);
        background-image: var(--bgImage, none);
        margin-top: calc(var(--vpad) / 3);
        @media screen and (min-width: 37.5em) {
            grid-template-columns: repeat(12, minmax(1em, 1fr));

            @for $i from 1 through 3 {
                &--cols#{$i} {
                    --span: #{math.div(12, $i)};
                }
                &--cols4 {
                    --span: 6;
                }
            }
        }
        @media screen and (min-width: 50em) {
            &--cols4 {
                --span: 3;
            }
        }
        &:where(:first-child) {
            margin-top: 0;
        }
        &[class*='bg-'] {
            padding: 0.5em;
        }
        &--narrow {
            max-width: $contWidthNarrow;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__col {
        background: var(--bgStrip, transparent);
        background-image: var(--bgImage, none);
        color: var(--colText, inherit);
        &[class*='bg-'] {
            padding: 0.5em;
        }
        @media screen and (min-width: 37.5em) {
            @for $i from 1 through 4 {
                &:nth-child(#{$i}) {
                    grid-column: span
                        calc(var(--span, 12) + var(--w#{$i}, 0) - var(--w#{$i - 1}, 0) + var(--adj, 0));
                }
            }
        }
    }
    &--alternate {
        #{$fr} {
            &__group {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(math.div($contWidth, 8), 1fr));
                column-gap: 0.5em;
            }
        }
    }
}
