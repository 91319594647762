// ! Account -----
.account {
    &__info {
        padding: calc(var(--vpad) / 3);
        background: var(--colGrigioChiaro);
        border-radius: 0.4em;
        margin-bottom: var(--vpad);
    }
    &__data {
        display: grid;
        grid-template-columns: repeat(2, auto);
        gap: calc(var(--vpad) / 3);
        justify-content: start;
        align-items: center;
        margin: calc(var(--vpad) / 3) 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        dt {
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.85em;
        }
    }
    &__exit {
        margin-top: var(--vpad);
    }
}
.box-address {
    &__header {
        display: flex;
        gap: 1em;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;
        > * {
            margin: 0;
        }
        .button {
            max-width: 50%;
            font-size: .85em;
        }
    }
    &__add {
        margin-left: auto;
    }
    &__item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-content: stretch;

        border: 1px solid var(--colGrigio);
        padding: calc(var(--vpad) / 3);
        margin-bottom: calc(var(--vpad) / 2);
        border-radius: 0.4em;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &__buttons {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-end;
        font-size: 0.8em;
        margin-left: calc(var(--vpad) / 2);
        .button {
            margin-bottom: 1em;
            margin-right: 0;
        }
    }
    &__alert {
        max-width: 16em;
        margin-top: calc(var(--vpad) / 2);
        margin-bottom: calc(var(--vpad) / 2);
        background: var(--colContrastoChiaro);
        padding: 0.5em;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
