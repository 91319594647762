$burger-height: null;
$burger-gutter: null;


// ! UTILITIES --------------------
@function decimal-round($number, $digits: 0, $mode: round) {
    $n: 1;
    // $number must be a number
    @if type-of($number) != number {
        @warn '#{ $number } is not a number.';
        @return $number;
    }
    // $digits must be a unitless number
    @if type-of($digits) != number {
        @warn '#{ $digits } is not a number.';
        @return $number;
    } @else if not unitless($digits) {
        @warn '#{ $digits } has a unit.';
        @return $number;
    }
    @for $i from 1 through $digits {
        $n: $n * 10;
    }
    @if $mode == round {
        @return round($number * $n) / $n;
    } @else if $mode == ceil or $mode == up {
        @return ceil($number * $n) / $n;
    } @else if $mode == floor or $mode == down {
        @return floor($number * $n) / $n;
    } @else {
        @warn '#{ $mode } is undefined keyword.';
        @return $number;
    }
}

// ! Font files ------
@mixin linkFont(
    $font,
    $name,
    $weight: normal,
    $style: normal,
    $path: '/inc/font/',
    $otf: false,
    $display: swap,
    $version: ''
) {
    $txt: '';
    @if $version != '' {
        $txt: '?';
    }
    @font-face {
        font-family: $name;
        @if $otf {
            src: url($path + $font + '.woff2' + $txt + $version) format('woff2'),
                url($path + $font + '.woff' + $txt + $version) format('woff'),
                url($path + $font + '.otf' + $txt + $version) format('opentype');
        } @else {
            src: url($path + $font + '.woff2' + $txt + $version) format('woff2'),
                url($path + $font + '.woff' + $txt + $version) format('woff'),
                url($path + $font + '.ttf' + $txt + $version) format('truetype');
        }
        font-weight: $weight;
        font-style: $style;
        font-display: $display;
    }
}

// ! Absolute cover -------
@mixin abs-cover($z-index: 0, $margin: 0) {
    position: absolute;
    top: $margin;
    left: $margin;
    right: $margin;
    bottom: $margin;
    z-index: $z-index;
}

// ! Absolute sides --------
@mixin abs-sides($margin: 0) {
    top: $margin;
    left: $margin;
    right: $margin;
    bottom: $margin;
}

// | Burger ----------
// Burger parts
// (---) top    -> &::before
// [---] middle -> & u
// (---) bottom -> &::after
@mixin burger(
    $width: 30px,
    $height: 5px,
    $gutter: 3px,
    $color: #000,
    $border-radius: 0,
    $transition-duration: 0.3s
) {
    $burger-height: $height !global;
    $burger-gutter: $gutter !global;

    display: block;
    position: relative;
    margin-top: $height + $gutter;
    margin-bottom: $height + $gutter;

    user-select: none;

    u,
    &::before,
    &::after {
        text-decoration: none;
        display: block;
        width: $width;
        height: $height;
        background-color: $color;
        @if $border-radius != 0 {
            border-radius: $border-radius;
        }

        transition-property: background-color, transform;

        transition-duration: $transition-duration;
    }

    &::before,
    &::after {
        position: absolute;
        content: '';
    }

    &::before {
        top: -($height + $gutter);
    }

    &::after {
        top: $height + $gutter;
    }
}

// Select parts of the burger
@mixin burger-parts {
    u,
    &::before,
    &::after {
        @content;
    }
}
@mixin burger-top {
    &::before {
        @content;
    }
}
@mixin burger-middle {
    u {
        @content;
    }
}
@mixin burger-bottom {
    &::after {
        @content;
    }
}

// Burger animations
@mixin burger-to-cross($color: auto) {
    u {
        background-color: transparent;
    }
    @if ($color != auto) {
        &::before,
        &::after {
            background-color: $color;
        }
    }
    &::before {
        transform: translateY($burger-gutter + $burger-height) rotate(45deg);
    }
    &::after {
        transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
    }
}