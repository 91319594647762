// ! Carrello / Progetto -----
.header {
    &__cart {
        position: relative;
        &__items {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 0;
            top: 0;
            font-size: 0.7em;
            border-radius: 50%;
            padding: 0.2em;
            min-width: 1.5em;
            color: var(--colBianco);
            background: var(--colPrimario);
            transform: translate(50%, 0);
            aspect-ratio: 1/1;
            .m-open & {
                color: var(--colPrimario);
                background: var(--colBianco);
            }
        }
    }
}
.cart {
    margin: calc(var(--vpad) / 2) 0;
    &:last-child {
        margin-bottom: 0;
    }
    &__title {
        @extend %fontTitoli;
        color: var(--colPrimario);
        margin-bottom: calc(var(--vpad) / 3);
        a {
            color: inherit;
            &:hover {
                color: var(--colContrasto);
            }
        }
    }
    &__section {
        margin-bottom: calc(var(--vpad) / 2);
        background: var(--colGrigioLeggero);
        border-radius: 0.4em;
        padding: calc(var(--vpad) / 3);
        &__footer {
            margin: calc(var(--vpad) / 3) calc(var(--vpad) / -3) calc(var(--vpad) / -3);
            padding: calc(var(--vpad) / 3);
            background: var(--colGrigioChiaro);
            border-radius: 0 0 0.4em 0.4em;
            font-size: 0.85em;
            @media screen and (min-width: $screenMedium) {
                font-size: 1em;
            }
        }
    }
    &__item {
        $pad: 1em;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: stretch;

        border-top: 1px solid var(--colPrimarioChiaro);

        flex-wrap: wrap;
        font-size: 0.85em;

        @media screen and (min-width: $screenMedium) {
            font-size: 1em;
        }

        @media screen and (min-width: $screenLarge) {
            flex-wrap: nowrap;
        }

        .cart__title + &,
        &:first-child {
            border-top: none;
        }
        &__image {
            flex: 0 0 auto;
            width: 12em;
            max-width: 33%;
            padding: $pad $pad $pad 0;
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
        &__description {
            flex: 1 1 auto;
            display: flex;
            flex-flow: column nowrap;
            padding: $pad 0;
            width: 66%;
            @media screen and (min-width: $screenLarge) {
                width: auto;
            }
        }
        &__name {
            @extend %fontTitoli;
            font-size: clamp(1.5em, 2vw, 2em);
            color: var(--colTitle, inherit);
            word-wrap: break-word;
            hyphens: auto;
            text-wrap: balance;
            margin-top: .2em;
            text-wrap-style: balance;
            a {
                color: inherit;
                &:hover {
                    color: var(--colContrasto);
                }
            }
        }
        &__price {
            margin-top: 0.25em;
            // text-align: right;
            @media screen and (min-width: $screenLarge) {
                text-align: unset;
            }
            .product__price {
                margin: 0;
                font-size: 1.4em;
                font-weight: $fontStrong;
            }
        }
        &__qty {
            --inputH: 2em;
            flex: 0 0 auto;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            text-align: right;

            width: 100%;
            padding-bottom: $pad;
            @media screen and (min-width: $screenLarge) {
                --inputH: 3em;
                width: auto;
                flex-flow: column nowrap;
                padding: $pad 0 $pad $pad;
            }

            .input--number {
                font-size: 0.8em;
            }
        }
        &__tools {
            margin-top: auto;
            padding-top: calc(var(--vpad) / 6);
            .button,
            button {
                font-size: 0.8em;
                margin-right: 1em;
                text-transform: uppercase;
                cursor: pointer;
                text-align: left;
            }
        }
        &__variante {
            font-weight: $fontStrong;
            margin-top: .25em;
        }
        &__note {
            margin-top: .5em;
            font-size: 0.8em;
            color: var(--colGrigioScuro);
        }
        &__opzioni {
            margin-top: .5em;
            font-size: 0.8em;
        }
    }

    &__message {
        &__title {
            @extend %fontTitoli;
            font-size: 0.8em;
            margin-bottom: 1em;
        }
    }

    &__delivery {
        --inputH: 2.5em;
        //font-size: .85em;
        margin-top: 1em;
        padding-top: 1em;
        border-top: 1px dotted var(--colGrigio);

        align-items: center;

        display: block;
        @media screen and (min-width: $screenMedium) {
            display: flex;
            flex-wrap: wrap;
        }

        @media screen and (min-width: $screenLarge) {
            flex-wrap: nowrap;
        }

        label {
            margin-bottom: 0;
        }
        &__title {
            width: 100%;
            margin-bottom: 0.5em;
            @media screen and (min-width: $screenMedium) {
                margin-right: 1em;
                margin-bottom: 0;
            }
        }
        &__day {
            display: flex;
            align-items: center;
            @media screen and (min-width: $screenLarge) {
                margin-right: 1em;
            }
            > * {
                @media screen and (min-width: $screenMedium) {
                    margin-right: calc(var(--vpad) / 3);
                }
            }
        }
        &__time {
            margin-top: 0.5em;
            @media screen and (min-width: $screenLarge) {
                margin-top: 1em;
            }
            @media screen and (min-width: $screenMedium) {
                margin-top: 0;
            }
        }
    }

    &__column {
        font-size: 0.9em;
        margin-top: var(--vpad);
        @media screen and (min-width: $screenMedium) {
            margin-top: 0;
        }
    }
    &__town {
        margin-top: 1em;
        font-size: 0.9em;
        p {
            padding: 0.5em;
            margin-bottom: 0 !important;
            background: var(--colGrigioLeggero);
            + p {
                padding-top: 0;
            }
            &:first-child {
                border-radius: 0.4em 0.4em 0 0;
            }
            &:last-child {
                border-radius: 0 0 0.4em 0.4em;
            }
        }
        strong {
            display: block;
        }
    }

    &__promo,
    &__subtotal,
    &__shipping,
    &__total {
        p {
            display: grid;
            grid-template-columns: repeat(2, minmax(1em, 1fr));
            gap: 0.2em 1em;
            justify-content: space-between;
            line-height: 1.3;
            margin-bottom: calc(var(--vpad) / 4);
        }
        margin-bottom: calc(var(--vpad) / 4);
    }
    &__note {
        order: 2;
        display: block;
        font-size: 0.8em;
        color: var(--colGrigioScuro);
    }
    &__shipping {
        margin-top: calc(var(--vpad) / 2);
        strong {
            text-transform: uppercase;
            font-size: 0.9em;
        }
    }
    &__promo {
        p {
            align-items: flex-end;
        }
        strong {
            font-size: 0.85em;
            text-transform: uppercase;
        }
        &__label {
            margin-right: auto;
        }
        &__del {
            align-self: flex-start;
            padding: 0.1em;
        }
    }
    &__total {
        font-size: 1.2em;
        font-weight: 700;
        border-top: 1px solid var(--colGrigio);
        margin-top: calc(var(--vpad) / 2);
        padding-top: 0.5em;
    }
    &__value {
        min-width: 45%;
        margin-right: 0.5em;
        text-align: right;
    }
    &__promo-text {
        font-size: 0.85em;
        padding-top: calc(var(--vpad) / 3);
        p {
            margin-bottom: 0.5em;
        }
    }
    &__checkout {
        container-type: inline-size;
    }
}

// ! Chechout ---
.checkout {
    &__item {
        margin-bottom: calc(var(--vpad) / 2);
        padding-bottom: calc(var(--vpad) / 2);
        border-bottom: 1px dotted var(--colGrigio);
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
        .button {
            font-size: 0.7em;
        }
        &__header {
            display: flex;
            gap: 1em;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            > * {
                margin: 0;
            }
            .button {
                max-width: 50%;
            }
        }
    }
    &__sblock {
        display: inline-block;
        margin-right: 0.5em;
    }
    &__alert {
        position: relative;
        z-index: 1;
        display: block;
        font-size: 0.9rem;
        // color: var(--colText);
        padding: 0.3em 0.7em;
        margin: calc(var(--vpad) / 2) 0;
        border-radius: 0.4em;
        overflow: hidden;
        &::before {
            content: '';
            @include abs-cover(-1);
            background: var(--colError);
            opacity: 0.2;
        }
    }
}
