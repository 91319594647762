// !Fonts --------------------

@if ($notA11y) {
    @include linkFont('Cosen Regular', 'Cosen', $otf: true);
    @include linkFont('Cosen Regular Italic', 'Cosen', 400, italic, $otf: true);
} @else {
    @include linkFont('Atkinson-Hyperlegible-Regular', 'Atkinson', 400, $otf: true);
    @include linkFont('Atkinson-Hyperlegible-Italic', 'Atkinson', 400, italic, $otf: true);
    @include linkFont('Atkinson-Hyperlegible-Bold', 'Atkinson', 700, $otf: true);
    @include linkFont('Atkinson-Hyperlegible-BoldItalic', 'Atkinson', 700, italic, $otf: true);
}
@include linkFont('fontello', 'fontello');
