@use 'sass:math';

// hack to import css ---
// @function max($numbers...) {
//     @return m#{a}x(#{$numbers});
// }

// @function min($numbers...) {
//     @return m#{i}n(#{$numbers});
// }
// -----------------------

$notA11y: true !default;

// Step responsive -----------
$screenLarge: 60em; // 960px
$screenMedium: 50em; // 800px
$screenSmall: 36em; // 576px
$screenXsmall: 340px;

// Dimensioni ----------------
$unit: 1rem !default;

$fontSize: 1;
$lineHeight: 1.4;
$contWidth: math.div(1200, 16) * $unit;
$contWidthNarrow: math.div(960, 16) * $unit;
$containerPad: calc(var(--vpad) / 1.5);
$containerPadMobile: calc(var(--vpad) / 2);
$np_columns-gutter: var(--vpad);

// Caratteri ---------------
$fontTesto: 'greycliff-cf', sans-serif;
$fontTitoli: 'Cosen', sans-serif;
// $fontNormal: 400;
$fontStrong: 600;
// $titWheight: 700;
$titTransform: uppercase;
$titleSize: 3.4em;
// $titLetterSpacing: 0.02em;

// Colori ----------------
$colBg: #fcfcfc;
$colNero: #0c0c0c;
$colBianco: #fcfcfc;

$colPrimario: #142342;
$colContrasto: #DAC773;
$colGrigio: #ccc;

$bgHalfSize: 25%; //calc(var(--vpad) * 3); // Dimensione mezzo sfondo
$bgHalfRotate: -90deg;
$bgGradRotate: 180deg;

// Immagini --------------
$logoCliente: '/img/caffe-gilli.svg';
$logoClienteWhite: '/img/caffe-gilli-white.svg';
