.bg {
    &-np {
        background: var(--bgStrip, transparent);
        background-image: var(--bgImage, none);
        color: var(--strip-text-color, inherit);
    }
    &-half {
        @media screen and (min-width: $screenMedium) {
            &-white {
                --bgImage: linear-gradient(#{$bgHalfRotate}, #fff #{$bgHalfSize}, transparent 0);
            }
            &-black {
                --bgImage: linear-gradient(
                    #{$bgHalfRotate},
                    var(--colNero) #{$bgHalfSize},
                    transparent 0
                );
                .strip__text_ {
                    > *:not(.strip__image) {
                        filter: Invert(100%);
                        mix-blend-mode: difference;
                    }
                }
            }
            &-grey {
                --bgImage: linear-gradient(
                    #{$bgHalfRotate},
                    #{var(--colGrigioChiaro)} #{$bgHalfSize},
                    transparent 0
                );
            }
        }
    }
    &-np-black {
        --bgStrip: var(--colNero);
        --colDots: var(--colBianco);
        --strip-text-color: var(--colBianco);
        --colTitle: '';
        a:not(.button) {
            color: inherit;
            &:hover {
                color: var(--colContrasto);
            }
        }
    }
    &-np-white {
        --bgStrip: var(--colBianco);
        --strip-text-color: var(--strip-text-color);
        color: var(--strip-text-color);
        a:not(.button, .read-more) {
            color: var(--colLink);
            &:hover {
                color: var(--colLinkHover);
            }
        }
    }
    &-np-grey {
        --bgStrip: var(--colGrigioLeggero);
    }
    &-grad {
        &-white {
            --bgImage: linear-gradient(#{$bgGradRotate}, #fff 0, transparent 100%);
        }
        &-black {
            --bgImage: linear-gradient(#{$bgGradRotate}, var(--colNero) 0, transparent 100%);
            .strip__text_ {
                > *:not(.strip__image) {
                    filter: Invert(100%);
                    mix-blend-mode: difference;
                }
            }
        }
        &-grey {
            --bgImage: linear-gradient(
                #{$bgGradRotate},
                #{var(--colGrigioChiaro)} 0,
                transparent 100%
            );
        }
    }
}
