@use 'sass:math';

@import 'modules/button';
@import './modules/strip_site';

::selection {
    background-color: var(--colPrimario);
    color: var(--colBianco);
}

.button {
    @include button();
}

.socials {
    list-style: none;
    margin: 0;
    padding: 0;
}

// ! PAGE-HEADER ===========================================================
.menu {
    &__switcher {
        &__container {
            &::before {
                @extend %animate2;
                .m-open & {
                    opacity: 1;
                    transform: translate3d(-50%, -50%, 0) scale3d(30, 30, 1);
                }
            }
        }
    }
    &__container {
        @extend %animate2;
        @include abs-sides(0);
        display: flex;
        flex-direction: column;
        position: fixed;
        z-index: 102;
        // background: var(--colPrimario);
        padding-bottom: calc(var(--vpad) / 2);
        padding-top: calc(var(--header-height) / 16 * 1em * var(--fontSize));
        z-index: 5;
        line-height: 1.2;
        color: var(--colBianco);
        pointer-events: none;

        @media screen and (min-width: 43.75em) {
            padding-left: var(--vpad);
            padding-right: var(--vpad);
        }

        .m-open & {
            visibility: visible;
            opacity: 1;
            transform: none;
        }
        a {
            color: inherit;
            &:hover {
                color: var(--colContrastoScuro);
            }
        }
        // li {
        //     @extend %animate;
        //     @media screen and not (prefers-reduced-motion: reduce) {
        //         transform: translate3d(0, -100%, 0);
        //         opacity: 0;

        //         @for $i from 1 to 10 {
        //             &:nth-child(#{$i}) {
        //                 transition-delay: ($i * 0.05s + 0.3s);
        //             }
        //         }

        //         .m-open &,
        //         .no-js & {
        //             transform: none;
        //             opacity: 1;
        //             &.off {
        //                 opacity: 0.5;
        //             }
        //         }
        //     }
        // }
    }
    &__row {
        max-width: $contWidth;
        margin: auto;
        display: grid;
        max-height: 100%;
        overflow: auto;
        padding: 1em 0;
        width: 100%;
        gap: calc(var(--vpad) / 2) var(--vpad);
        padding-left: calc(var(--vpad) / 2);
        padding-right: calc(var(--vpad) / 2);
        .m-open & {
            pointer-events: all;
        }
        @media screen and (min-width: 43.75em) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__main {
        @extend %fontTitoli;
        margin: auto 0;
        width: 100%;
        @media screen and (min-width: 43.75em) {
            padding: 1em 0;
            max-height: 100%;
            overflow: auto;
        }
        ul {
            display: grid;
            gap: 1em;
            @media screen and (min-width: 43.75em) {
                grid-auto-flow: column;
                grid-template-rows: repeat(4, minmax(1em, 1fr));
                gap: 2vh var(--vpad);
            }
        }
        li {
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (min-width: 43.75em) {
                justify-content: flex-start;
            }
        }

        a {
            font-size: clamp(2.25em, 4vw, 4.2em);
            transition: none;
            display: inline-grid;
            grid-template-areas: 'main';
            line-height: 1.2;

            div {
                @extend %animate;
                display: block;
                padding-right: 0.2em;
                grid-area: main;
                clip-path: inset(0 0 0 0);
                transition-delay: 0.2s;
                + div {
                    transition-delay: 0s;
                    font-style: italic;
                    clip-path: inset(0 100% 0 0);
                    color: var(--colPrimarioChiaro);
                }
            }

            .is-mouse &:hover {
                color: inherit;
                div {
                    clip-path: inset(0 0 0 100%);
                    transition-delay: 0s;
                    + div {
                        transition-delay: 0.2s;
                        clip-path: inset(0 0 0 0);
                    }
                }
            }
        }
    }

    &__lang {
        &__menu {
            @extend %animate2;
            text-transform: uppercase;
            display: grid;
            position: absolute;
            right: 0;
            top: 100%;
            z-index: 10;

            list-style: none;
            background: var(--colBg);
            border: 1px solid;
            opacity: 0;
            transform: translate3d(0, -50%, 0);
            &.sm-open {
                visibility: visible;
                opacity: 1;
                transform: none;
            }
        }
        a {
            padding: 0.5em;
            display: block;
            line-height: 1.3;
            color: var(--colText);
            &:hover {
                background: var(--colPrimarioChiaro);
                color: var(--colText);
            }
            &.active {
                font-weight: $fontStrong;
            }
        }
    }

    &__contact {
        display: grid;
        gap: 1em;
        text-align: center;
        font-weight: $fontStrong;
        padding: calc(var(--vpad) / 2);
        @media screen and (min-width: $screenSmall) {
            display: none;
        }
        span {
            display: block;
            margin-bottom: 0.5em;
            &::before {
                margin: 0;
                font-size: 1.4em;
            }
        }
        img {
            margin: 0 auto;
            display: block;
            width: 2em;
            height: auto;
        }
    }

    &__socials {
        display: flex;
        gap: 1.5em;
        justify-content: center;
        text-align: center;
        font-weight: $fontStrong;
        padding: 1em calc(var(--vpad) / 2) 0;
        @media screen and (min-width: 43.75em) {
            padding-left: 0;
            padding-right: 0;
        }
        .socials {
            gap: 1.5em;
            display: flex;
        }
        a {
            &:hover {
                color: inherit;
                opacity: 0.7;
            }
        }
    }
}

// ! PAGE-CONTENT ===========================================================
.page {
    &__content {
        padding-top: calc(var(--header-height) / 16 * 1em / var(--fontSize));
    }
}

.word-wrapper {
    display: inline-flex;
    overflow: hidden;
    margin-top: -0.15em;
    .word {
        padding-top: 0.15em;
    }
}

// ! Banner -------
.banner {
    @media screen and (min-width: $screenSmall) {
        padding: var(--vpad) var(--container-pad, var(--vpad)) 0;
    }
    &__slider,
    &__slide {
        height: auto;
        .home & {
            height: calc(100vh - var(--header-height, 0) * 1px);
            @media screen and (min-width: $screenSmall) {
                height: auto;
            }
        }
    }
    &__slide {
        video,
        picture,
        img {
            @media screen and (min-width: $screenSmall) {
                position: static;
                width: 100%;
                height: auto;
            }
        }
        //     &::after {
        //         content: '';
        //         @include abs-cover(1);
        //         background: linear-gradient(to bottom, var(--colBianco) -10%, transparent 20%);
        //     }
    }
    &__watermark {
        @include abs-cover(5);
        background: transparent url('/img/watermark.svg') no-repeat 50% 50%;
        background-size: 200px 30px;
        opacity: 0.3;
        pointer-events: none;
    }
    &__text {
        max-width: 100%;
        .home & {
            text-align: left;
            top: auto;
            transform: none;
            bottom: calc(var(--vpad) * 1.5);
            @media screen and (min-width: $screenSmall) {
                bottom: calc(var(--vpad) * 2);
            }
            @media screen and (min-width: $screenMedium) {
                bottom: 25%;
            }
        }
        // &__container {
        //     --aniProp: transform, opacity;
        //     > * {
        //         @extend %animate2;
        //         transform: translate3d(0, 70%, 0);
        //         .home & {
        //             opacity: 0;
        //             transform: translate3d(70%, 0, 0);
        //         }
        //         @for $i from 1 to 7 {
        //             &:nth-child(#{$i}) {
        //                 transition-delay: ($i * 0.05s);
        //             }
        //         }
        //         .no-js &,
        //         .slick-active & {
        //             opacity: 1;
        //             transform: none;
        //         }
        //     }
        // }
    }
    &__credits {
        position: absolute;
        bottom: calc(var(--vpad) / 3);
        right: var(--vpad);
        color: var(--colBianco);
        z-index: 5;
        font-size: 0.7em;
    }

    &__form {
        display: block;
        position: absolute;
        bottom: 5em;
        left: 0;
        right: 0;
        z-index: 20;
    }
    &__slogan {
        @extend %fontTitoli;
        font-size: clamp(var(--titleSize) / 1.2, 3vw, var(--titleSize));
        color: var(--colBianco);
        margin-bottom: calc(var(--vpad) / 2);
        em {
            font-style: normal;
            display: block;
        }
    }
    &__search {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        background: var(--colBianco);
        padding: 0.5em;
        border-radius: 0.3em;
        gap: calc(var(--vpad) / 2);
        p {
            width: 25%;
            &:last-child {
                align-self: stretch;
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;
                padding-right: 0.5em;
            }
        }
        label {
            font-size: 0.85em;
            text-align: center;
            margin-bottom: 0.2em;
        }
        select {
            border: none;
            text-align: center;
            font-weight: $fontStrong;
            height: auto;
        }
    }
    &__link {
        text-transform: uppercase;
        color: var(--colBianco);
        position: relative;
        z-index: 1;
        display: inline-block;
        padding: 0.5em;
        overflow: hidden;
        font-weight: $fontStrong;
        &::after {
            @extend %animate;
            content: '';
            @include abs-cover(-1);
            background: var(--colBianco);
            transform: translateY(calc(100% - 2px));
        }
        &:hover {
            color: var(--colText);
            &::after {
                transform: none;
            }
        }
    }
}

.breadcrumbs {
    padding: 0.3em 0;
    text-align: center;
    &.js-fixed {
        position: fixed;
        z-index: 89;
        left: 0;
        right: 0;
        top: calc(var(--header-height) * 1px + var(--menuPageHeight, 0px));
        background: var(--colBianco);
        margin-top: 0;
        transform: none;
    }
    &__container {
        .js-fixed & {
            @extend %container;
        }
    }
    a {
        color: inherit;
    }
    span {
        font-size: 0.85em;
        &:not(:first-child) {
            &::before {
                content: '/';
                font-weight: $fontNormal;
                display: inline-block;
                margin: 0 0.4em 0 0.2em;
                vertical-align: middle;
            }
        }
        &:last-child {
            font-weight: $fontStrong;
        }
    }
}

.accordion {
    &__switch {
        &::after {
            $w: 1.5em;
            @extend %animate;
            @extend %fontIcons;
            @extend %icon-arrow-down;
            flex: 0 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 calc(var(--vpad) / 4);
            font-size: 1rem;
            width: $w;
            height: $w;
            color: var(--colContrasto);
        }
        .no-js &,
        &.active {
            &::after {
                transform: rotate3d(0, 0, 1, 180deg);
            }
        }
    }
}

// .gm-style {
//     .gm-style-iw {
//         border-radius: 0;
//     }
// }

.map {
    &__content {
        @extend %fontTesto;
        font-size: 1rem;
        text-align: inherit;
        padding-top: 0.5em;
    }
    &__logo {
        height: 24px !important;
        width: auto !important;
        margin: 0 auto 1.5em !important;
    }
    &__image {
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto 0.7em;
    }
    &__title {
        @extend %fontTitoli;
        font-size: 1.4em;
        margin-bottom: 0.5em;
    }
    &__text {
        font-size: 0.95em;
    }
    &__marker {
        &__type {
            text-transform: uppercase;
            font-size: 0.75em;
            color: var(--colGrigioMedio);
        }
        &__title {
            text-transform: uppercase;
            font-weight: $fontStrong;
        }
    }
}

.slider {
    &__buttons {
        display: grid;
        grid-template-columns: repeat(2, minmax(1em, 1fr));
        gap: 1em;
        .button {
            margin: 0;
        }
    }
}

.scroll-title-fixed {
    position: fixed;
    z-index: 20;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.js-scroll-container {
    overflow: hidden;
    display: flex;
    > * {
        flex: 0 0 auto;
    }
    .strip__data,
    .strip__text {
        min-width: 100%;
        display: flex;
    }
    [class*='strip__title'] {
        white-space: nowrap;
        text-transform: uppercase;
        color: var(--colPrimarioLeggero);
        margin: 0;
        padding-left: 0.15em;
    }
    &.activated {
        .js-scroll-content,
        [class*='strip__title'] {
            animation: strip-marquee 10s linear infinite;
        }
    }
    @keyframes strip-marquee {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(-100%, 0, 0);
        }
    }
}

.awards {
    &-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        gap: calc(var(--vpad) / 2);
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        img {
            max-width: 10em;
            max-height: 4em;
            object-fit: contain;
        }
    }
}

.side-popup {
    $pop: &;
    @extend %animate2;
    position: fixed;
    z-index: 101;
    top: 0;
    right: 0;
    height: 100%;
    width: 70em;
    max-width: 95%;
    background: var(--colBg);
    transform: translate3d(100%, 0, 0);
    &-open {
        overflow: hidden;
        #{$pop} {
            transform: none;
            &__screen {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &__screen {
        @extend %animate2;
        position: fixed;
        z-index: 99;
        @include abs-sides(0);
        background: transparent;
        backdrop-filter: blur(4px);
        opacity: 0;
        visibility: hidden;
        .side-open & {
            opacity: 1;
            visibility: visible;
        }
    }
    &__close {
        position: absolute;
        z-index: 10;
        top: calc(var(--vpad) / 2);
        right: var(--vpad);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3em;
        height: 3em;
        background: var(--colBianco);
        color: var(--colPrimario);
        border-radius: 50%;
        &::before {
            @extend %fontIcons;
            @extend %icon-menu-close;
            margin: 0;
        }
    }
    &__conts {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .ajax-page {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .strip:not(.strip--image) {
        display: flex;
        height: 100%;
        overflow: hidden;
        .container {
            display: flex;
        }
        .strip__text {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}

.card {
    &__banner {
        img {
            max-height: 30vh;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    &__contents {
        padding: var(--vpad) 0;
        flex: 1 1 auto;
        height: 1em;
        display: flex;
    }
    &__scroll {
        height: 100%;
        overflow: auto;
        flex: 1 1 auto;
    }
    &__section {
        padding: 0 var(--container-pad);
        @media screen and (min-width: $screenSmall) {
            padding: 0 var(--vpad);
        }
    }
    &__title {
        @extend %fontTitoli;
        font-size: clamp(1.1em, 3vw, 1.8em);
        position: sticky;
        top: 0;
        background: var(--bgStrip, var(--colBg));
        padding: 1rem 0;
        border-bottom: 1px solid var(--colGrigio);
        &:hover {
            color: var(--colPrimarioChiaro);
        }
        &::after {
            color: inherit;
            font-size: 0.9em;
        }
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: 1em 0 0;
        border-bottom: 1px solid var(--colGrigio);
    }
    &__item {
        display: grid;
        grid-template-columns: minmax(1em, 1fr) 6em;
        gap: 0 1em;
        margin-bottom: 1em;
    }
    &__plate {
        @extend %fontTitoli;
        grid-column: span 2;
        font-size: 1.3em;
        font-size: clamp(0.9em, 2.4vw, 1.3em);
    }
    &__price {
        text-align: right;
    }
}

.scroll {
    &__container {
        overflow: hidden;
        position: relative;
    }
    &__item {
        overflow: auto;
        padding-bottom: 40px;
        margin-bottom: -30px;
        user-select: none;
        .is-touch & {
            scroll-snap-type: x mandatory;
        }
    }
    &__content {
        display: inline-flex;
        .strip {
            width: 90vw;
            scroll-snap-align: start;
            @media screen and (min-width: $screenMedium) {
                flex: 0 0 auto;
                width: 120vw;
                height: 100vh;
                .container {
                    max-width: none;
                }
            }
            &--3-cols {
                @media screen and (min-width: $screenMedium) {
                    --span: 4;
                    --strip-col-bgPad: -2;
                    .container {
                        --strip-column-gap: var(--hpad, var(--vpad));
                        grid-template-columns: repeat(12, minmax(1em, 1fr));
                    }
                }
            }
            &__column {
                &.full-height {
                    min-height: 0;
                    @media screen and (min-width: $screenMedium) {
                        min-height: 100vh;
                    }
                }
            }
            &__column--image {
                .strip {
                    @media screen and (min-width: $screenMedium) {
                        &__image img {
                            width: calc(100% + var(--container-pad, var(--vpad)));
                        }
                    }
                }
            }
            &__image {
                img {
                    max-height: 40vh;
                    object-fit: contain;
                    @media screen and (min-width: $screenMedium) {
                        max-height: none;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    &__handle {
        $w: 4.5em;
        --aniProp: opacity;
        @extend %animate;
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: $w;
        height: $w;
        color: var(--colPrimario);
        background: var(--colBianco);
        border-radius: 50%;
        transform: translate3d(-50%, -50%, 0);
        pointer-events: none;
        opacity: 0.8;
        &.visible {
            opacity: 1;
        }
        &::before {
            @extend %fontIcons;
            @extend %icon-drag;
            font-size: 2.6em;
            margin: 0 !important;
        }
    }
}

// SHOP -----------------
.box-grid {
    $grid: &;
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: var(--vpad) 1.5em;
    // grid-auto-flow: dense;
    grid-area: main;
    padding-bottom: 1em;
    @media screen and (min-width: $screenXsmall) {
        grid-template-columns: repeat(2, minmax(1em, 1fr));
    }
    @media screen and (min-width: $screenSmall) {
        grid-template-columns: repeat(auto-fill, minmax(min(33%, var(--item-width, 14em)), 1fr));
    }
    &--h {
        --item-width: 20em;
        --item-span: 2;
    }
    &__header {
        min-height: var(--menuPage-height, 2.4em);
        position: static;
        &__container {
            @extend %animate;
            transition-property: top;

            &.js-fixed {
                position: fixed;
                left: 0;
                right: 0;
                background-color: var(--bgStrip, var(--colBg));
                z-index: 15;
                top: calc((var(--header-height) - var(--filters-adjust, 0)) * 1px);
                .header-up & {
                    top: calc(var(--filters-adjust, 0) * 1px);
                }
            }
        }
        &__content {
            --h-pad: 0px;
            display: flex;
            gap: 1em;
            justify-content: space-between;
            align-items: center;
            padding: 1em var(--h-pad);
            @media screen and (min-width: $screenSmall) {
                --h-pad: 1em;
            }
            .js-fixed & {
                margin: 0 auto;
                max-width: calc(
                    var(--container-maxWidth, #{$contWidth}) + var(--container-pad, var(--vpad)) * 2
                );
                padding: 1em calc(var(--container-pad, var(--vpad)) + var(--h-pad));
            }
        }
    }
    &__sort {
        display: flex;
        align-items: center;
        gap: 0.25em;
        font-size: 0.85em;
        @media screen and (min-width: $screenSmall) {
            gap: 0.5em;
            font-size: 1em;
        }
        select {
            width: auto;
            border-bottom: none;
        }
        label {
            margin: 0;
            transform: none;
        }
    }
    &__screen {
        @extend %animate2;
        content: '';
        @include abs-cover(9);
        opacity: 0;
        visibility: hidden;
        backdrop-filter: blur(4px);
        .f-open & {
            opacity: 1;
            visibility: visible;
        }
    }
    &__content {
        position: relative;
        overflow: hidden;
        display: grid;
        grid-template-areas: 'main';
    }
    &__filters {
        @extend %animate2;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        background: var(--bgStrip, var(--colBg));
        transform: translate3d(-100%, 0, 0);
        position: fixed;
        z-index: 110;
        @media screen and (min-width: $screenSmall) {
            grid-area: main;
            position: static;
            z-index: 10;
            padding: var(--vpad);
            padding-left: 0;
            // position: absolute;
            width: 25em;
            max-width: 95%;
        }
        .f-open & {
            transform: none;
        }
        &__close {
            position: absolute;
            z-index: 10;
            top: calc(var(--vpad) / 2);
            right: var(--vpad);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3em;
            height: 3em;
            background: var(--colPrimarioChiaro);
            color: var(--colPrimario);
            border-radius: 50%;
            @media screen and (min-width: $screenSmall) {
                display: none;
            }
            &::before {
                margin: 0;
            }
        }
    }
    &__progress {
        margin-bottom: 1em;
        text-align: center;
        &::before {
            display: inline-block;
            content: attr(data-progress-text);
            margin-bottom: 0.5em;
        }
        &__bar {
            position: relative;
            margin: 0 auto;
            width: 20em;
            max-width: 95%;
            height: 0.4em;
            border-radius: 0.2em;
            background-color: var(--colGrigio);
            overflow: hidden;
            &::after {
                @extend %animate;
                content: '';
                @include abs-cover(1);
                border-radius: 0.2em;
                background: var(--colPrimario);
                transform: translate3d(var(--flt-progress, 0), 0, 0);
            }
        }
    }
    &__buttons {
        margin-top: var(--vpad);
        text-align: center;
        // grid-area: main;
        // align-self: end;
    }
}

.filters-list {
    padding: 0 var(--vpad);
    @media screen and (min-width: $screenSmall) {
        padding: 0;
        max-height: calc(100vh - var(--header-height) * 1px);
        overflow: auto;
    }
    &.js-kiv {
        @media screen and (min-width: $screenSmall) {
            @include animate($prop: padding-top);
            padding-top: calc(var(--header-height) * 1px);
            .header-up & {
                padding-top: 0;
            }
        }
    }
    &__container {
        height: 100%;
        padding: calc(var(--header-height) * 1px) 0 var(--vpad);
        overflow: auto;
        @media screen and (min-width: $screenSmall) {
            overflow: visible;
            padding: 0;
        }
    }
    &__section {
        border-bottom: 1px solid var(--colGrigio);
    }
    &__title {
        font-weight: $fontStrong;
        display: flex;
        gap: 1em;
        padding: 1em 0;
        &[data-toggle] {
            cursor: pointer;
            &::after {
                @extend %fontIcons;
                @extend %icon-plus;
                margin: 0;
                margin-left: auto;
                font-size: 1.2em;
            }
        }
        &.toggle-open {
            &::after {
                @extend %icon-minus;
            }
        }
        span {
            display: block;
            position: relative;
            &:after {
                position: absolute;
                left: 100%;
                top: 0;
                width: 8px;
                height: 8px;
                margin-left: 4px;
                background: var(--colContrasto);
                border-radius: 50%;
                .has-filters & {
                    content: '';
                }
            }
        }
    }
    &__options {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        // gap: 1em;
        li {
            padding-bottom: 0.5em;
        }
    }
    &__range {
        display: flex;
        gap: 1em;
        align-items: center;
        input {
            border: 1px solid;
            padding: 0.3em;
            text-align: center;
        }
    }
    &__delivery {
        gap: 0.75em;
        align-items: center;
        padding: 1rem 0;
        &::before,
        &::after {
            display: none !important;
        }
        span {
            font-weight: $fontStrong;
        }
        img {
            flex: 0 0 auto;
            width: 2em;
        }
    }
    &__check {
        $w: 40px;
        $d: 4;
        position: relative;
        cursor: pointer;
        background-color: var(--colGrigio);
        width: $w;
        height: math.div($w, 2) + $d;
        border-radius: math.div($w, 2) + $d;
        transition: 0.4s;
        margin-left: auto;
        @media screen and (min-width: $screenSmall) {
            margin-left: 0;
        }
        &::before {
            position: absolute;
            content: '';
            height: math.div($w, 2) - $d;
            width: math.div($w, 2) - $d;
            left: $d * 1px;
            bottom: $d * 1px;
            background-color: var(--colBianco);
            border-radius: 50%;
            transition: 0.4s;
        }
        input:checked + label & {
            background-color: var(--colPrimario);
            &::before {
                transform: translateX(#{math.div($w, 2) - $d});
            }
        }
        input:focus + label & {
            box-shadow: 0 0 1px var(--colPrimario);
        }
        input:disabled + label & {
            opacity: 0.6;
            cursor: no-drop;
        }
    }
    &__slider {
        margin: calc(var(--vpad) / 2) 0.5em;
    }

    &__buttons {
        margin-top: var(--vpad);
        display: flex;
        flex-wrap: wrap;
        gap: 1.5em;
        justify-content: center;
        align-items: center;
        .button {
            flex: 1 1 auto;
            min-width: 0;
        }
    }
    &__reset {
        display: flex;
        gap: 0.4em;
        font-weight: $fontStrong;
        align-items: center;
        &::after {
            @extend %animate;
            @extend %fontIcons;
            @extend %icon-cancel;
            font-size: 1.2em;
            margin: 0;
            display: flex;
            width: 1.6em;
            height: 1.6em;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: var(--colBianco);
            background: var(--colPrimarioChiaro);
        }
        &:hover {
            &::after {
                background: var(--colPrimario);
            }
        }
    }
}

.product-list {
    $grid: &;
    &__total {
        display: none;
    }
    &__item {
        @media screen and (min-width: $screenXsmall) {
            --item-span: 2;
        }
        @media screen and (min-width: 48em) {
            --item-span: 3;
        }
        &--large {
            grid-column: 1 / span var(--item-span, 1);
            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &__link {
        color: inherit;
        display: grid;
        gap: 1em;
        &:hover {
            color: inherit;
            #{$grid} {
                &__image {
                    img {
                        transform: scale3d(1.05, 1.05, 1);
                    }
                }
                &__title {
                    color: var(--colPrimarioChiaro);
                    .bg-custom-3 & {
                        color: var(--colBianco);
                    }
                }
            }
        }
    }

    &__image {
        overflow: hidden;
        img {
            @extend %animate2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text {
        display: grid;
        gap: 0.5em;
    }

    &__header {
        gap: 0.5em;
        font-weight: $fontStrong;
        line-height: 1.2;
        text-wrap: balance;
        display: grid;
        @media screen and (min-width: $screenSmall) {
            justify-content: space-between;
            display: flex;
        }
    }
    &__price {
        white-space: nowrap;
        text-align: right;
    }

    &__title {
        @extend %animate2;
    }

    &__buttons {
        margin-top: var(--vpad);
        text-align: center;
    }
}

.detail {
    &__container {
        .strip__title1 {
            word-wrap: break-word;
            hyphens: auto;
        }
    }
    &__switch,
    &__label {
        font-weight: $fontStrong;
        display: flex;
        gap: 1em;
        font-size: 1em;
        transform: none;
    }
    &__switch {
        padding: 0.5em 0;
        margin-bottom: 1em;
        border-bottom: 2px solid;
        &.js-accordion-switch {
            cursor: pointer;
            &::after {
                @extend %fontIcons;
                @extend %icon-plus;
                margin: 0;
                margin-left: auto;
                font-size: 1.2em;
            }
        }
        &.active {
            &::after {
                @extend %icon-minus;
            }
        }
    }
    &__dett {
        padding-bottom: 0.5em;
    }
    &__textarea {
        border: 1px solid var(--colPrimarioChiaro);
        border-radius: 0.4em;
        padding: 0.5em;
        &::placeholder {
            color: var(--colPrimarioChiaro);
        }
    }
    &__footer {
        padding-top: calc(var(--vpad) / 2);
        margin-top: auto;
        &__bottom {
            display: flex;
            align-items: flex-end;
            gap: 1em;
        }
    }
    &__total {
        margin-left: auto;
    }
    &__price {
        font-weight: $fontStrong;
        font-size: 1.5em;
    }
}

.tray-choice {
    list-style: none;
    margin: 1em 0;
    padding: 0;
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(6em, 1fr));
    align-items: end;
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
    label {
        text-align: center;
        margin-bottom: 0.3em;
        transform: none;
        font-size: 0.9em;
        text-wrap-style: balance;
    }
    input[type='number'] {
        &[readonly] {
            background-color: transparent;
        }
    }
    &__active {
        outline: 1px solid var(--colPrimarioChiaro);
    }
    &__taken {
        font-size: 1.4em;
        font-weight: $fontStrong;
    }
}

.text-counter {
    display: block;
    text-align: right;
    padding: 0 0.5em;
    margin-top: $lineHeight * -1em;
    color: var(--colPrimarioChiaro);
    pointer-events: none;
}

// ! PAGE-FOOTER ===========================================================
.page__footer {
    background: var(--colPrimario);
    color: var(--colBianco);
    padding: var(--vpad) 0 calc(var(--vpad) / 2);
    a {
        color: inherit;
        display: inline-block;
        &:hover {
            color: inherit;
            opacity: 0.7;
        }
    }
}
.footer {
    &__top {
        padding: calc(var(--vpad) * 1.5) 0 var(--vpad);
        display: grid;
        position: relative;
        gap: 1em;
        @media screen and (min-width: 40em) {
            grid-template-columns: repeat(2, minmax(1em, 1fr));
        }
        &::before {
            @extend %animate2;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            border-top: 1px solid;
            .waiting & {
                transform: scale3d(0, 1, 1);
            }
        }
    }
    &__address {
        text-align: center;

        @media screen and (min-width: 40em) {
            display: flex;
            flex-direction: column;
            text-align: left;
        }
        &__title {
            display: flex;
            align-items: baseline;
            gap: 0.5em;
            font-weight: $fontStrong;
            margin-bottom: 0.5em;
            justify-content: center;
            @media screen and (min-width: 40em) {
                justify-content: unset;
            }
            img {
                display: block;
                margin-top: -2em;
                width: 2em;
                height: auto;
            }
        }
    }
    &__shortcuts {
        padding-top: calc(var(--vpad) / 2);
        margin-top: auto;
        font-weight: $fontStrong;
        ul {
            display: grid;
            gap: 0.5em;
        }
    }
    &__tools {
        text-align: center;
        @media screen and (min-width: 40em) {
            display: flex;
            flex-direction: column;
            text-align: right;
        }
    }
    &__menu {
        font-weight: $fontStrong;
        ul {
            display: grid;
            gap: 0.5em;
        }
    }
    &__socials {
        display: flex;
        gap: 1.5em;
        text-align: center;
        font-weight: $fontStrong;
        justify-content: center;
        margin: var(--vpad) 0;
        @media screen and (min-width: 40em) {
            padding-top: calc(var(--vpad) / 2);
            margin: auto 0 0;
            justify-content: flex-end;
        }
        .socials {
            gap: 1.5em;
            display: flex;
        }
    }
    &__last-line {
        margin-top: 0.3em;
    }
    &__mid {
        padding-bottom: calc(var(--vpad) * 1.5);
    }
    &__bottom {
        font-size: 0.85em;
        line-height: 1.2;
        padding-top: calc(var(--vpad) / 2);
        display: grid;
        gap: var(--vpad);
        position: relative;
        @media screen and (min-width: $screenSmall) {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            gap: 0;
        }
        &::before {
            @extend %animate2;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            border-top: 1px solid;
            transition-delay: 0.3s;
            .waiting & {
                transition-delay: 0;
                transform: scale3d(0, 1, 1);
            }
        }
    }
    &__legal {
        display: flex;
        flex-direction: column;
        gap: 1em;
        text-align: center;
        @media screen and (min-width: $screenSmall) {
            text-align: unset;
        }
        @media screen and (min-width: 56.25em) {
            flex-direction: row;
            gap: 0;
        }
    }
    &__credits {
        text-align: right;
        @media screen and (min-width: $screenSmall) {
            flex: 0 0 auto;
            text-align: unset;
        }
    }
}

.scroll-top {
    a {
        $w: 4.5em;
        width: $w;
        height: $w;
        color: var(--colPrimario);
        background: var(--colPrimarioChiaro);
        border-radius: 50%;
        opacity: 0.8;
        &:hover {
            opacity: 1;
            // background: var(--colContrasto);
        }
        &::before {
            font-size: 1.4em;
        }
    }
}
