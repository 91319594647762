@use 'sass:math';

.video {
    &__thumb {
        position: relative;
        display: block;
        &::before {
            @extend %fontIcons;
            @extend %icon-play-circled;
            position: absolute;
            z-index: 2;
            left: 50%;
            top: 50%;
            color: #fff;
            font-size: 2.5em;
            background: rgba($colNero, 0.5);
            border-radius: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &__controls {
        position: absolute;
        bottom: calc(var(--vpad) / 3);
        left: 50%;
        z-index: 15;
        width: 95%;
        //max-width: $contWidthNarrow;

        display: flex;
        align-items: center;
        color: #fff;
        transform: translateX(-50%);
        button,
        span {
            display: flex;
            font-size: inherit;
            cursor: pointer;
        }
        .strip__video__container:not(.active) & {
            display: none;
        }
    }
    &__play {
        @extend [class^='icon-'];
        @extend .icon-play;
        padding: 0.2em;
        cursor: pointer;
        &.playing {
            @extend .icon-pause;
        }
    }
    &__play-cursor {
        --w: 3em;
        @extend %animate;
        // @extend [class^='icon-'];
        // @extend .icon-play;
        transition-property: opacity;
        color: var(--cursorText, inherit);
        padding: 0.2em;
        position: absolute;
        z-index: 10;
        left: calc(90% - var(--w) / 2);
        top: calc(10% + var(--w) / 2);

        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--w);
        height: var(--w);
        //pointer-events: none;
        transform: translate3d(-50%, -50%, 0);
        @media screen and (min-width: 36em) {
            --w: 3em;
        }
        &::before {
            margin: 0;
            font-size: 1em;
            text-transform: uppercase;
            font-weight: 300;
            content: attr(aria-label);
        }
        &::after {
            content: '';
            @include abs-cover(-1, calc(var(--w) * -1));
            border: 1px solid var(--colContrasto);
            background: rgba(#fff, 0.4);
            border-radius: 50%;
            opacity: 0.9;
        }
        // &.playing {
        //     &::before {
        //         content: 'Pause';
        //     }
        // }
        .is-touch & {
            &.playing {
                opacity: 0;
            }
        }
        &.off-controls,
        &.off {
            opacity: 0;
            visibility: hidden;
        }
    }
    &__sound {
        @extend [class^='icon-'];
        @extend .icon-sound-off;
        padding: 0.2em;
        cursor: pointer;
        &:first-child {
            margin-left: auto;
        }
        &.playing {
            @extend .icon-sound-on;
        }
    }
    &__prog {
        position: relative;
        z-index: 1;
        flex: 1 1 auto;
        margin: 0 calc(var(--vpad) / 2);
        padding-bottom: 1em;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            border-top: 1px solid;
            opacity: 0.5;
        }
        &__ind {
            @extend %animate;
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 3px;
            margin-top: -1px;
            background: #fff;
            transform-origin: 0 50%;
            transform: scale3d(0, 1, 1);
        }
    }
    &__fullscreen {
        @extend [class^='icon-'];
        @extend .icon-fullscreen;
        padding: 0.2em;
        cursor: pointer;
        &:first-child {
            margin-left: auto;
        }
    }
    &__time {
        font-size: 0.75em;
        min-width: 7em;
        text-align: right;
        margin: 0 .5em;
    }
}

html:not(.np_a11y) [data-video-controls~='play_cursor'] {
    cursor: none;
}
