$d: 5vh;
@media screen and (prefers-reduced-motion: no-preference) {
    .animated {
        &:not(.strip__column):not([class*='strip__blurb']),
        &.strip__column .strip__column__content,
        .strip__blurb,
        .strip__counter {
            @include animate2();
            transition-duration: 1.2s;
            //transition-property: opacity, transform;
            //transition-delay: .5s;
            //transition: all 1.5s cubic-bezier(0.77, 0, 0.175, 1) .3s;
            // transition: all 1.5s cubic-bezier(0.4, 0, 0.2, 1) 0.3s;
            transition-property: opacity, transform;
        }
        @media screen and (min-width: 36em) {
            &.strip__column--2 .strip__column__content {
                transition-delay: 0.5s !important;
            }
            &.strip__column--3 .strip__column__content {
                transition-delay: 1s !important;
            }
        }
        @for $i from 1 to 20 {
            &:nth-child(#{$i}) {
                transition-delay: ($i * 0.1s);
            }
        }
    }
    .waiting {
        pointer-events: none;
        &.anima {
            &--fade {
                &:not(.strip__column):not([class*='strip__blurb']),
                &.strip__column .strip__column__content,
                .strip__blurb,
                .strip__counter {
                    opacity: 0;
                }
            }
            &--left-in {
                &:not(.strip__column):not([class*='strip__blurb']),
                &.strip__column .strip__column__content,
                .strip__blurb,
                .strip__counter {
                    opacity: 0;
                    transform: translate3d(#{-$d}, 0, 0);
                }
            }
            &--right-in {
                &:not(.strip__column):not([class*='strip__blurb']),
                &.strip__column .strip__column__content,
                .strip__blurb,
                .strip__counter {
                    opacity: 0;
                    transform: translate3d(#{$d}, 0, 0);
                }
            }
            &--top-in {
                &:not(.strip__column):not([class*='strip__blurb']),
                &.strip__column .strip__column__content,
                .strip__blurb,
                .strip__counter {
                    opacity: 0;
                    transform: translate3d(0, #{-$d}, 0);
                }
            }
            &--bottom-in {
                &:not(.strip__column):not([class*='strip__blurb']),
                &.strip__column .strip__column__content,
                .strip__blurb,
                .strip__counter {
                    opacity: 0;
                    transform: translate3d(0, #{$d}, 0);
                }
            }
        }
    }
}
[data-word] {
    display: inline-flex;
}
.t {
    &-s {
        display: inline-flex;
        overflow: hidden;
        padding: 0 0 0.07em;
        // font-size: var(--fontSize, 1em);
    }
    &-p {
        display: inline-flex;
        line-height: 1.1;
        transform-origin: 0 100%;
        @media screen and (prefers-reduced-motion: reduce) {
            transform: none !important;
            opacity: 1 !important;
        }
    }
}

@media (prefers-reduced-motion: reduce) {
    *,
    ::before,
    ::after {
        animation: none !important;
        transition: none !important;
        scroll-behavior: auto !important;
        // opacity: 1 !important;
    }
}
