@use 'sass:math';

$titTransform: none !default;
$titWheight: 400 !default;
$titLetterSpacing: unset !default;
$fontNormal: 400 !default;
// ! Extended ----------------
%fontTesto {
    font-family: $fontTesto;
    font-weight: $fontNormal;
}
%fontTitoli {
    font-family: $fontTitoli;
    font-weight: $titWheight;
    line-height: 1;
    text-transform: $titTransform;
    letter-spacing: $titLetterSpacing;
}
%focus-visible {
    &:not(.mce-content-body),
    body:not(.mce-content-body) & {
        outline: 3px solid var(--colContrasto);
        box-shadow: 0 0 8px rgba(var(--colPrimario), 0.8);
        outline-offset: 2px;
    }
}
%borderLink {
    --aniProp: transform;
    display: inline-flex;
    position: relative;
    color: inherit;
    font-weight: $fontStrong;
    &::after {
        @extend %animate2;
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        border-bottom: 2px solid var(--colContrastoScuro);
        transform: scale3d(0, 1, 1);
        transform-origin: 0 50%;
    }
    a:hover &,
    &:hover {
        color: inherit;
        &::after {
            transform: none;
        }
    }
}
%actFigure {
    overflow: hidden;
    position: relative;
    &::after {
        @extend %animate2;
        content: '';
        @include abs-cover(1);
        background: #000;
        opacity: 0;
        transform: scale3d(50%, 50%, 1);
    }
    img {
        @extend %animate2;
    }
}
%actFigureHover {
    .is-mouse & {
        &::after {
            opacity: 0.3;
            transform: none;
        }
        img {
            transform: scale3D(1.1, 1.1, 1);
        }
    }
}
%iconRounded {
    @extend %animate;
    @extend %fontIcons;
    font-size: 1.8em;
    background: transparent;
    color: var(--colContrasto);
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5em;
    height: 2.5em;
    border: 1px solid var(--colContrasto);
    border-radius: 50%;
    a:hover &,
    &:hover {
        color: var(--colPrimario);
        background: var(--colContrasto);
    }
}
%navigation-list {
    $w: 0.6em;
    --m: 0.5em;
    list-style: none;
    margin: 0;
    padding: 0;
    @media screen and (min-width: 68.75em) {
        --m: 1em;
    }
    li {
        display: flex;
        line-height: 1.2;
        padding: var(--m) 0;
        position: relative;
        z-index: 0;
        .text-right & {
            justify-content: flex-end;
        }
        &:first-child {
            padding-top: 0;
            &::before {
                display: none;
            }
            &::after {
                top: calc(50% + #{math.div($w, 2)} - var(--m) / 2);
            }
        }
        &:last-child {
            padding-bottom: 0;
            &::before {
                bottom: calc(50% + #{math.div($w, 2)} - var(--m) / 2);
            }
            &::after {
                display: none;
            }
        }
        &::before,
        &::after {
            content: '';
            position: absolute;
            z-index: -1;
            left: math.div($w, 2);
            border-left: 1px solid var(--colContrasto);
            .text-right & {
                left: auto;
                right: math.div($w, 2);
            }
        }
        &::before {
            bottom: calc(50% + #{math.div($w, 2)});
            top: 0;
        }
        &::after {
            top: calc(50% + #{math.div($w, 2)});
            bottom: 0;
        }
    }
    a {
        display: inline-flex;
        align-items: center;
        color: var(--colGrigio);
        &::before {
            content: '';
            display: block;
            flex: 0 0 auto;
            width: $w;
            height: $w;
            border: 1px solid var(--colContrasto);
            border-radius: 50%;
            margin-right: 0.5em;
        }
        &.active {
            color: var(--colText);
            &::before {
                background: var(--colContrasto);
            }
        }
        .text-right & {
            flex-direction: row-reverse;
            &::before {
                margin-left: 0.5em;
                margin-right: 0;
            }
        }
    }
}
