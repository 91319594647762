@use 'sass:math';

/* ! Form ---------------- */
.form {
    &-honeypot {
        display: none;
    }
    &__popup {
        --colTitle: #fff;
        width: 100%;
        height: 100%;
        background: var(--colPrimario);
        color: #fff;
    }
    &__grid {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        label.error {
            width: 100%;
            order: 5;
        }
        input[type] {
            flex: 1 1 auto;
            width: auto;
            height: auto;
        }
        &__button {
            flex: 0 0 auto;
            height: auto;
            margin: 0;
            padding: 0.3em 1em;

            color: inherit;
            border-bottom: 1px solid;

            @media screen and (max-width: 36em) {
                // ! mobile
                width: 100%;
                margin-top: 1em;
            }
        }
    }
}
.warning {
    color: var(--colError);
}
legend {
    @extend %fontTitoli;
    display: block;
    // font-size: clamp(var(--titleSize) / 3, 2vw, var(--titleSize) / 2.8);
    font-size: 1em;
    margin: 0 0 calc(var(--vpad) / 3);
    grid-column: 1 / -1;
}
fieldset {
    margin-top: var(--vpad);
    .bld-form__col &,
    &:first-child {
        margin-top: 0;
    }
    .np_a11y & {
        border-color: var(--colGrigioScuro);
        padding-left: calc(var(--vpad) / 2);
        padding-right: calc(var(--vpad) / 2);
    }
}
.label,
label {
    @extend %animate;
    transition-property: transform;
    max-width: 100%;
    font-size: 1em;
    display: block;
    text-align: left;
    margin-bottom: 0.5em;
    line-height: 1.2;
    // text-transform: capitalize;

    transform-origin: 0 0;
    transform: translate3d(0.2em, (var(--inputH)), 0);
    .compiled &,
    &.static {
        transform: scale3d(0.9, 0.9, 1);
    }
    .required,
    &.required {
        &::after {
            content: '*';
            display: inline-block;
            margin-left: 0.3em;
            color: var(--colError);
        }
    }
    &.error {
        color: var(--colError);
        display: block;
        margin-top: 0.3em;
        transform: none;
    }
    small {
        font-size: 0.85em;
        color: var(--colGrigio);
    }
    img {
        max-width: 13em;
        width: 100%;
        height: auto;
        object-fit: contain;
    }
}

input[type='text'],
input[type='password'],
input[type='search'],
input[type='email'],
input[type='date'],
input[type='number'],
input[type='url'],
input[type='file'],
textarea,
select {
    display: block;
    width: 100%;
    max-width: 100%;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    padding: 0.3em 0;
    margin: 0;
    font-size: 1em;
    line-height: 1.2;
    border: none;
    border-bottom: 1px solid;
    background: transparent;
    -webkit-appearance: none;
    appearance: none;
    height: var(--inputH);
    border-radius: 0;
    &[readonly] {
        opacity: 0.6;
        cursor: not-allowed;
        background: var(--colGrigioChiaro);
        .text-small & {
            height: auto;
            padding: 0.3em 0.7em;
        }
        .flatpickr-input + & {
            opacity: 1;
            background: inherit;
            cursor: pointer;
        }
    }
    &.input--small {
        height: auto;
        padding: 0.3em 0.7em;
    }
    .is-touch & {
        font-size: 1rem !important;
    }
}
input[type='text'],
input[type='password'],
input[type='search'],
input[type='email'],
input[type='date'],
input[type='time'],
input[type='number'],
input[type='url'],
textarea {
    &.parsley-error {
        border-color: var(--colError);
    }
    &.flt-error {
        color: var(--colError);
        border-color: var(--colError);
    }
    &::placeholder {
        color: inherit;
    }
}
input[type='file'] {
    padding: 0.75em;
    padding-left: 0;
    font-size: 0.9em;
    text-align: left;
    height: auto;
    // border: 1px solid;
}
textarea {
    height: auto;
}
select {
    overflow: hidden;
    padding-right: 2em;
    // padding-left: 0.5em;
    margin: 0;
    background-repeat: no-repeat;
    background-position: right 1em top 50%;
    background-size: 0.65em auto;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDQ2MCAyNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoyOyI+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLC03MywtMjcwKSI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMC41LC0wLjUsMC41LDAuNSwzLDMxMCkiPgogICAgICAgICAgICA8cGF0aCBkPSJNMTQwLDQ2MEwxNDAsNjBMODAsNjBMODAsNTIwTDU0MCw1MjBMNTQwLDQ2MEwxNDAsNDYwWiIgc3R5bGU9ImZpbGw6IzFEMUQxRDsiLz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
    // .bg-np-dark & {
    // background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDQ2MCAyNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoyOyI+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLC03MywtMjcwKSI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMC41LC0wLjUsMC41LDAuNSwzLDMxMCkiPgogICAgICAgICAgICA8cGF0aCBkPSJNMTQwLDQ2MEwxNDAsNjBMODAsNjBMODAsNTIwTDU0MCw1MjBMNTQwLDQ2MEwxNDAsNDYwWiIgc3R5bGU9ImZpbGw6I2ZmZmZmZjsiLz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
    // }
    &::-ms-expand {
        display: none;
    }
    option {
        font-weight: normal;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        margin: 0.5em 0;
    }
    &.nofull {
        width: auto;
        max-width: 100%;
    }
}

input[type='checkbox'],
input[type='radio'] {
    @if ($notA11y) {
        $is: 1em;
        // visibility: hidden;
        opacity: 0;
        position: absolute;
        z-index: -1;
        + label {
            font-size: 0.9em;
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            // align-items: center;
            cursor: pointer;
            text-transform: none;
            padding-left: 0;
            transform: none;
            font-weight: 400;
            opacity: 1;
            &::before,
            &::after {
                @extend %animate;
                display: flex;
                justify-content: center;
                align-items: center;
                width: $is;
                min-width: $is;
                height: $is;
                line-height: $is;
                text-align: center;
                border: 1px solid var(--colPrimario);
                background: transparent;
                margin-right: 1em;
                // margin-top: 0.2em;
            }
            &::before {
                content: '';
            }
            &::after {
                font-family: 'fontello';
                @extend %icon-cancel;
                display: none;
                order: -1;
                color: var(--colPrimario);
                border-color: var(--colPrimario);
                background: transparent;
                line-height: 1.1;
            }
            &:hover {
                &::before {
                    border-color: var(--colPrimarioChiaro);
                }
                &::after {
                    color: var(--colBianco);
                    background: var(--colPrimario);
                }
            }
            &.no-check {
                &::before,
                &::after {
                    content: unset;
                }
            }
        }
        &:checked {
            + label {
                &::before {
                    display: none;
                }
                &::after {
                    display: flex;
                }
            }
        }
        &:disabled {
            + label {
                color: var(--colGrigioMedio);
                pointer-events: none;
                &::before {
                    border-color: var(--colGrigioChiaro);
                }
                &::after {
                    border-color: var(--colGrigioChiaro);
                    background: var(--colGrigioChiaro);
                }
            }
        }
        &:focus-visible {
            + label {
                @extend %focus-visible;
            }
        }
    } @else {
        + label {
            display: unset;
        }
    }
}
@if ($notA11y) {
    input[type='radio'] {
        + label {
            &::before,
            &::after {
                border-radius: 50%;
            }
            &::after {
                content: '•';
            }
        }
    }
}

.input {
    &--icon {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: flex-start;
        position: relative;
        input[type] {
            flex: 1 1 auto;
            grid-row: 1/1;
            grid-column: 1/1;
            padding-left: 2.5em;
        }
        &::before {
            grid-row: 1/1;
            grid-column: 1/1;
            color: var(--colGrigioMedio);
            font-size: 1.2em;
            position: relative;
            z-index: 1;
            padding: 0.3em 0.7em;
        }
    }
    &--number {
        display: flex;
        overflow: hidden;
        border: 1px solid var(--colPrimarioChiaro);
        // border-radius: .4em;
        // max-width: calc(var(--inputH) * 3.5);
        input[type='number'] {
            border: none;
            width: var(--inputH);
            flex: 1 1 auto;
            text-align: center;
            border-radius: 0;
            color: inherit;
            appearance: textfield;
            &::-webkit-inner-spin-button {
                appearance: none;
            }
        }
        button {
            color: inherit;
            font-size: 1.4em;
            border: none;
            background: transparent;
            width: var(--inputH);
            flex: 0 0 auto;
            cursor: pointer;
            &.off {
                color: var(--colGrigio);
                // background: var(--colGrigioChiaro);
                cursor: auto;
                pointer-events: none;
            }
        }
    }
}

.disclaimer {
    display: none;
    font-size: 0.85em;
    padding: 0.5em;
    border: 1px solid var(--colGrigio);
    background: var(--colBg);
    color: var(--colText);
    margin: 0.5em 2em;
    opacity: 0.8;
    text-align: left;
    a {
        color: var(--colLink);
    }
}

[class*='g-recaptcha'] {
    &,
    div,
    iframe {
        max-width: 100%;
    }
    div {
        margin: 0 auto;
    }
}

.dropdown {
    position: relative;
    text-align: left;
    font-size: 0.9em;
    &:last-child {
        ul {
            left: auto;
            right: 0;
        }
    }
    &:hover {
        background-position: right center;
    }
    &__container {
        padding: 0;
        transform: none;
        max-width: none;
    }
    &__label {
        font-style: normal;
        font-weight: $fontStrong;
    }
    span {
        @extend select;
        padding-left: 1em;
        white-space: nowrap;
        .is-touch & {
            font-size: inherit !important;
        }
    }
    [type='checkbox'] {
        visibility: visible;
        z-index: 1;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        &:checked {
            position: fixed;
            z-index: +0;
            right: 0;
            bottom: 0;
            + ul {
                display: block;
            }
            ~ span u {
                visibility: hidden;
            }
        }
    }
    label {
        font-size: inherit;
        margin: 0;
    }
    ul {
        text-align: left;
        position: absolute;
        top: 100%;
        margin-top: -1px;
        border: 1px solid #ccc;
        border-radius: 3px;
        left: 0;
        list-style: none;
        padding: 4px 0;
        display: none;
        background-color: white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.175);
        width: 100%;
        min-width: 200px;
        z-index: 1;
    }
    li {
        display: block;
        padding: 6px 20px;
        white-space: nowrap;
        min-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.active {
            @extend [class^='icon-'];
            @extend .icon-check;
            font-weight: $fontStrong;
        }
    }
}

.parsley {
    &-errors-list {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        margin-top: 0.5em;
        font-size: 0.9em;
        color: var(--colError);
        & + & {
            display: none !important;
        }
    }
}
