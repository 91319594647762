.share {
    &-div {
        &__grid {
            list-style: none;
            margin: 0;
            padding: 0;
            display: inline-flex;
            gap: 1.5em;
            margin-top: 1em;
        }
        .share {
            &__label {
                @apply sr-only;
            }
            &__link {
                $w: 2em;
                display: flex;
                justify-content: center;
                align-items: center;
                width: $w;
                height: $w;
                border-radius: 50%;
                color: var(--colBianco);
                background: var(--colPrimario);
                &::before {
                    margin: 0;
                }
            }
        }
    }
    &-win {
        width: 30em;
        max-width: 100%;
        text-align: center;
        &__logo {
            margin-bottom: 1em;
            span {
                display: block;
                background: transparent url($logoCliente) 50% 50% no-repeat;
                background-size: contain;
                height: 60px;
            }
        }
        &__grid {
            list-style: none;
            margin: calc(var(--vpad) / 2) 0;
            padding: 0;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
            gap: calc(var(--vpad) / 4);
        }
    }
    &-menu {
        @if ($notA11y) {
            @extend %animate;
            position: absolute;
            z-index: 10;
            right: 0;
            top: 100%;
            min-width: 16em;
            padding: .5em;
            background: var(--colBg);
            visibility: hidden;
            opacity: 0;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
            // transform: translateX(-50%);
            &.active {
                visibility: visible;
                opacity: 1;
            }
        }
        &__container {
            position: relative;
        }
        &__list {
            list-style: none;
            padding: 0;
            display: grid;
            gap: .5em;
            a {
                width: 100%;
                color: var(--colText);
                border: 1px solid var(--colGrigio);
                border-radius: .4em;
                display: block;
                padding: .3em;
                &::before {
                    color: var(--colPrimario);
                }
            }
        }
    }
}
