@use 'sass:math';
@import 'typography';
// Strip adjust -------------

.strip {
    $this: &;
    --hpad: var(--vpad);
    // overflow: hidden;
    .container {
        .slickslider & {
            max-width: none;
            padding-left: 0;
            padding-right: 0;
        }
    }
    &--full-height {
        min-height: 100vh;
        .container {
            align-self: center;
        }
        #{$this} {
            &__text {
                height: auto;
            }
        }
    }
    @media screen and (min-width: $screenMedium) {
        &--columns {
            .bg-narrow {
                --strip-col-bgPad: -3;
            }
        }
    }

    &--decoration {
        &-v {
            position: relative;
            z-index: 0;
            &::before {
                @extend %animate2;
                animation-delay: 0.4s;
                position: absolute;
                left: 50%;
                top: 0;
                bottom: 0;
                border-left: 2px solid var(--colDecoration, var(--colContrasto));
                transform-origin: 50% 0;
                @media screen and (min-width: $screenMedium) {
                    content: '';
                }
            }
            &.js-waiting-deco-top::before {
                transform: scale3d(1, 0, 1);
            }
        }
        &-h {
            position: relative;
            z-index: 0;
            &::after {
                @extend %animate2;
                animation-delay: 0.6s;
                content: '';
                position: absolute;
                // left: var(--container-pad, var(--vpad));
                // right: var(--container-pad, var(--vpad));
                left: 0;
                right: 0;
                top: 0;
                border-top: 2px solid var(--colDecoration, var(--colContrasto));
                transform-origin: 50% 50%;
            }
            &.js-waiting-deco-bottom::after {
                transform: scale3d(0, 1, 1);
            }
        }
    }

    &__column {
        @media screen and (min-width: $screenMedium) {
            ul,
            ol {
                --ulPad: 1.5em;
            }
            &.full-height {
                min-height: 100vh;
                #{$this} {
                    &__image {
                        &:only-child {
                            img {
                                height: 100vh;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }
        &--sticky {
            #{$this} {
                &__text {
                    align-self: flex-start;
                    position: sticky;
                    top: 30vh;
                }
            }
        }
    }

    &--image {
        &#{$this}--wide {
            #{$this} {
                &__text {
                    // max-width: calc(
                    //     var(--container-maxWidth) + var(--container-pad, var(--vpad)) * 2 +
                    //         var(--container-width-adjust, 0px)
                    // );
                    max-width: none;
                }
            }
        }
    }

    &__gallery {
        $gal: &;
        --col-width: 250px;
        .strip--wide & {
            margin-left: auto;
            margin-right: 0;
            width: calc(100vw - var(--vpad));
            max-width: calc((100% + #{$contWidth}) / 2);
            .splide {
                &__track {
                    padding-right: max(
                        50vw - #{math.div($contWidth, 2)},
                        var(--container-pad, var(--vpad))
                    ) !important;
                }
            }
        }
        &__item {
            margin-right: var(--vpad);
        }
        &__link {
            img {
                @extend %animate2;
            }
            &:hover {
                img {
                    opacity: 0.8;
                    transform: scale3d(0.98, 0.98, 1);
                }
            }
        }
        &--slider {
            img {
                max-width: 80vw;
                width: auto;
                height: 300px;
                max-height: 50vh;
                object-fit: cover;
                @media screen and (min-width: $screenSmall) {
                    object-fit: contain;
                    height: 500px;
                    max-height: 60vh;
                    max-width: none;
                }
            }
        }
    }

    &__counter {
        &__values {
            text-align: inherit;
            canvas {
                display: none;
            }
            &__main {
                font-size: 3.8em;
                color: var(--strip-text-color, inherit);
                span {
                    color: var(--colContrasto);
                }
            }
        }
    }

    &__blurb {
        $blurb: &;
        &__title {
            font-size: clamp(var(--titleSize) / 3, 2.4vw, var(--titleSize) / 2.5);
        }
        &__item {
            border-radius: 0.4em;
        }
        &__link {
            #{$blurb} {
                &__item {
                    @extend %animate2;
                    transition-property: transform, box-shadow;
                    &[class*='bg-custom'],
                    &[class*='bg-np'] {
                        &::before {
                            transform: none;
                        }
                    }
                }
            }
            &:hover {
                #{$blurb} {
                    &__item[class*='bg-custom'],
                    &__item[class*='bg-np'] {
                        color: inherit;
                        transform: scale3d(1.05, 1.05, 1);
                        box-shadow: 0 2px 0.75em rgba(#000, 0.2);
                        &::before {
                            opacity: 0.02;
                        }
                    }
                }
            }
        }
        &__image {
            margin-bottom: calc(var(--vpad) / 2);
        }
        &__text {
            a {
                &[href^='mailto:'],
                &[href^='http'] {
                    display: inline-block;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    &__map {
        height: 60em;
    }

    // STILI ANIMAZIONI SCROLL -----------

    &.js-image-cover {
        transform: scaleZ(1);
        &.js-fixed {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            transform: scaleZ(1) !important;
        }
        #{$this} {
            &__image {
                align-items: center;
                height: 35em;
                max-height: 80vh;
                @media screen and (min-width: $screenLarge) {
                    max-height: 100vh;
                }
                picture {
                    overflow: hidden;
                    height: 100%;
                    @media screen and (min-width: $screenLarge) {
                        position: fixed;
                        z-index: 1;
                        left: 50%;
                        top: 50%;
                        height: max(100vh, 100%);
                        width: 100vw;
                        overflow: visible;
                        transform: translate3d(-50%, -50%, 0);
                    }
                    .builder & {
                        position: absolute;
                        // clip-path: none;
                        width: 100%;
                        height: 100%;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    &.js-scroll-n-clip {
        overflow: hidden;
        @media screen and (min-width: $screenMedium) {
            height: 100vh;
        }
        .builder & {
            height: auto;
        }
        #{$this} {
            @media screen and (min-width: $screenMedium) {
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;
                width: 100%;
                height: 100vh;
                transform: translate(100vw, 0);
                &:first-child {
                    transform: none;
                }
                &__image {
                    img {
                        height: 100vh;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    &.js-image-slide {
        transform: scaleZ(1);
        overflow: hidden;
        #{$this} {
            &__image {
                picture {
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    @media screen and (min-width: 50em) {
                        position: fixed;
                    }
                    .builder & {
                        position: static;
                        overflow: hidden;
                    }
                }
                img {
                    height: 100vh;
                    width: 100%;
                    object-fit: cover;
                    transform: scale3d(1.2, 1.2, 1);
                    .builder & {
                        height: 100%;
                    }
                }
            }
        }
    }
}
