@use 'sass:math';

@import '../../../node_modules/@splidejs/splide/dist/css/splide-core.min';

@if ($notA11y) {
    .splide {
        $splide: &;
        &.show-arrows,
        &:hover {
            #{$splide} {
                &__arrow:not([disabled]) {
                    opacity: 1;
                }
            }
        }
        #{$splide}-arrows--top & {
            padding-top: 5rem;
        }

        &__track {
            .no-cursor & {
                pointer-events: none;
            }
        }

        &__pagination {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin-top: calc(var(--vpad) / 2);
            li {
                margin: 0 0.25em;
                &:only-child {
                    display: none;
                }
            }
            button {
                position: relative;
                display: block;
                // overflow: hidden;
                text-indent: 99rem;
                background: none;
                color: transparent;
                padding: 0;
                cursor: pointer;
                width: 3em;
                @include animate();
                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 50%;
                    width: 100%;
                    background: var(--colDots, var(--colText));
                    height: 3px;
                    border-radius: 1px;
                }
                &::before {
                    opacity: 0.5;
                    @include animate();
                }
                &::after {
                    transform-origin: 0 50%;
                    transform: scale3d(0, 1, 1);
                }
                &.is-active {
                    &::after {
                        animation: activeDot var(--sliderTime, 0.3s) linear forwards;
                    }
                }
            }
            @keyframes activeDot {
                0% {
                    transform: scale3d(0, 1, 1);
                }
                100% {
                    transform: none;
                }
            }
        }

        &__arrows {
            // position: absolute;
            // z-index: 10;
            // bottom: var(--vpad);
            // right: calc(var(--vpad) / 2);
            // display: grid;
            gap: 1rem;
            font-size: 1.4em;
            .splide-arrows--top & {
                bottom: 100%;
                right: 0;
                margin-bottom: 1rem;
                grid-template-columns: repeat(2, minmax(1em, 1fr));
            }
        }

        &__arrow {
            --colTextBottoni: var(--colPrimario);
            --colFondoBottoni: var(--colBianco);
            // --colBordoBottoni: var(--colBianco);
            --buttonPosition: absolute;
            --buttonZ: 10;
            top: 50%;
            opacity: .7;
            transform: translateY(-50%);
            &:hover {
                --colTextBottoniHover: var(--colContrasto);
            }
            .is-mouse & {
                opacity: 0;
            }

            svg {
                display: none;
            }
            &[disabled] {
                display: none;
            }
            & + & {
                margin: 0 !important;
            }
            &--prev {
                left: .2em;
            }
            &--next {
                right: .2em;
            }
        }

        &__handle {
            $w: 4.5em;
            --aniProp: opacity;
            @extend %animate;
            position: absolute;
            z-index: 10;
            left: 50%;
            top: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: $w;
            height: $w;
            color: var(--colPrimario);
            background: var(--colBianco);
            border-radius: 50%;
            transform: translate3d(-50%, -50%, 0);
            pointer-events: none;
            opacity: 0.8;
            &.visible {
                opacity: 1;
            }
            &::before {
                @extend %fontIcons;
                @extend %icon-drag;
                font-size: 2.6em;
                margin: 0 !important;
            }
            .is-touch & {
                display: none;
            }
        }
    }
}
[data-splide-free] {
    cursor: grab;
    &:active {
        cursor: grabbing;
    }
}
