.side {
    &-open {
        overflow: hidden;
    }
    &__container {
        @extend %animate2;
        display: block;
        background: var(--colBianco);
        padding: calc(var(--vpad) / 2);

        @container (min-width: 50em) {
            padding: calc(var(--vpad) / 2) var(--vpad);
        }

        .side-open & {
            transform: none;
        }
    }

    &__content {
        transition: opacity 0.3s ease-in-out;
        display: flex;
        flex-flow: column nowrap;
        height: 100%;

        &.loading {
            opacity: 0;
        }
    }

    &__close {
        position: absolute;
        font-size: 0.75em;
        text-transform: uppercase;
        right: calc(var(--vpad) / 4);
        top: calc(var(--vpad) / 2);

        @container (min-width: 50em) {
            right: calc(var(--vpad) / 2);
        }

        span {
            color: var(--colNero);
        }
    }

    &__footer {
        margin-top: auto;
    }

    &__screen {
        @extend %animate2;
        @include abs-cover(10);
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        opacity: 0;
        background: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(4px);

        .loading & {
            visibility: visible;
            opacity: 1;
        }

        .spinner {
            margin: 100px auto 0;
            width: 70px;
            text-align: center;

            > div {
                width: 18px;
                height: 18px;
                background-color: var(--colContrasto);

                border-radius: 100%;
                display: inline-block;
                animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            }

            .bounce1 {
                animation-delay: -0.32s;
            }

            .bounce2 {
                animation-delay: -0.16s;
            }
        }
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

.side-cart {
    &__items {
        overflow: auto;
        margin: calc(var(--vpad) / 2) 0;
        padding-right: 1em;
        container-type: inline-size;
    }

    &__item {
        display: grid;
        margin-top: 1em;
        padding-top: 1em;
        border-top: 1px solid var(--colPrimarioChiaro);
        gap: 1em;

        grid-template-columns: clamp(4em, 10%, 8em) minmax(1em, 1fr);

        &:first-child {
            margin-top: 0;
            padding-top: 0;
            border-top: none;
        }

        &__image {
            img {
                width: 100%;
                object-fit: contain;
                margin: 0 auto;

                @container (min-width: 50em) {
                    margin: 0;
                }
            }
        }

        &__description {
            display: grid;
            gap: 0.5em;
            grid-template-columns: repeat(2, auto);

            // @container (min-width: 20em) {
            //     grid-template-columns: repeat(2, minmax(1em, 1fr));
            // }
            .cart__checkout & {
                font-size: 0.8em;
            }
        }

        &__data {
            .cart__checkout & {
                grid-column: span 2;
            }
        }

        &__name {
            @extend %fontTitoli;
            font-size: clamp(1.1em, 1.8vw, 1.8em);
            color: var(--colTitle, inherit);
            word-wrap: break-word;
            hyphens: auto;
            text-wrap: balance;
            margin-top: .2em;
        }

        &__variante {
            font-weight: $fontStrong;
            margin-top: .25em;
        }
        &__note {
            margin-top: .5em;
            font-size: 0.9em;
            color: var(--colGrigioScuro);
        }
        &__opzioni {
            margin-top: .25em;
            font-size: 1em;
        }

        &__qty {
            grid-column: span 2;

            @container (min-width: 20em) {
                grid-column: unset;
                text-align: right;
                align-self: end;
            }
        }

        &__price {
            // margin-top: 0.25em;
            // text-align: right;
            order: 2;
            grid-column: span 2;

            @container (min-width: 20em) {
                order: unset;
                grid-column: unset;
            }

            .product__price {
                margin: 0;
                font-size: 1.4em;
                font-weight: $fontStrong;
            }
        }

        &__tools {
            --inputH: 2.2em;
            display: flex;
            flex-flow: column nowrap;
            padding-left: calc(var(--vpad) / 4);
            font-size: 0.9em;
            align-items: flex-end;
        }
    }

    &__buttons {
        text-align: right;
        [class*='icon-'] {
            &::before {
                @extend %animate;
                margin-right: 0;
                font-size: 1.2em;
                color: var(--colPrimarioChiaro);
                vertical-align: middle;
            }
            &:hover {
                &::before {
                    color: var(--colPrimario);
                }
            }
        }

        span {
            text-transform: uppercase;
            font-size: 0.75em;
        }
    }

    &__promo,
    &__subtotal,
    &__total {
        p {
            display: flex;
            justify-content: space-between;
            line-height: 1.3;
            margin-bottom: 0.5rem;
        }

        p:nth-child(2) {
            margin-bottom: 0;
        }
    }

    &__promo {
        p {
            align-items: flex-end;
        }

        strong {
            font-size: 0.85em;
            text-transform: uppercase;
        }

        &__label {
            margin-right: auto;
        }

        &__del {
            align-self: flex-start;
            padding: 0.1em;
        }
    }

    &__total {
        text-transform: uppercase;
        //font-weight: $fontStrong;
        border-top: 2px solid var(--colNero);
        border-bottom: 2px solid var(--colNero);
        margin: 1rem 0;
        padding: 1rem 0;
        font-size: 1em;

        @container (min-width: $screenMedium) {
            font-size: 1.2em;
        }
    }

    &__value {
        min-width: 45%;
        margin-right: 0.5em;
        text-align: right;
        font-weight: $fontStrong;
    }

    &__promo-text {
        font-size: 0.85em;
        padding-top: 1rem;

        p {
            margin-bottom: 0.5em;
        }
    }
    &__recap {
        .buttons {
            display: grid;
            gap: 1rem;
            justify-content: center;
            a {
                font-size: 1em;
                &:not(.button) {
                    color: var(--colNero);
                }
            }
        }
    }
}
